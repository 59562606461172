var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "작업이력 정보" },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", {
                          attrs: {
                            label: "작업완료",
                            icon: "check",
                            color: "purple",
                          },
                        })
                      : _vm._e(),
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", { attrs: { label: "저장", icon: "save" } })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
                [
                  _c("c-datepicker", {
                    attrs: {
                      editable: _vm.editable,
                      required: true,
                      type: "datetime",
                      label: "실제 시작일시",
                      name: "col3",
                      minuteStep: 10,
                    },
                    model: {
                      value: _vm.data.col3,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col3", $$v)
                      },
                      expression: "data.col3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
                [
                  _c("c-datepicker", {
                    attrs: {
                      editable: _vm.editable,
                      required: true,
                      type: "datetime",
                      label: "실제 완료일시",
                      name: "col4",
                      minuteStep: 10,
                    },
                    model: {
                      value: _vm.data.col4,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col4", $$v)
                      },
                      expression: "data.col4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
                [
                  _c("c-select", {
                    attrs: {
                      editable: _vm.editable,
                      type: "edit",
                      comboItems: _vm.comboItems1,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col1",
                      label: "고장분류",
                    },
                    model: {
                      value: _vm.data.col1,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col1", $$v)
                      },
                      expression: "data.col1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
                [
                  _c("c-select", {
                    attrs: {
                      editable: _vm.editable,
                      type: "edit",
                      comboItems: _vm.comboItems2,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col2",
                      label: "고장코드",
                    },
                    model: {
                      value: _vm.data.col2,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col2", $$v)
                      },
                      expression: "data.col2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                },
                [
                  _c("c-select", {
                    attrs: {
                      editable: _vm.editable,
                      type: "edit",
                      comboItems: _vm.comboItems3,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col20",
                      label: "작업유형",
                    },
                    model: {
                      value: _vm.data.col20,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col20", $$v)
                      },
                      expression: "data.col20",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                },
                [
                  _c("c-textarea", {
                    attrs: {
                      editable: _vm.editable,
                      rows: 2,
                      label: "작업 내용",
                      name: "col70",
                    },
                    model: {
                      value: _vm.data.col70,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col70", $$v)
                      },
                      expression: "data.col70",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c(
                    "c-mobile-table",
                    {
                      ref: "grid1",
                      attrs: {
                        title: "작업자",
                        topBorderClass: "topcolor-orange",
                        columns: _vm.grid1.columns,
                        data: _vm.grid1.data,
                        gridHeightAuto: true,
                        isTitle: true,
                        editable: _vm.editable && !_vm.disabled,
                        hideBottom: true,
                        isExcelDown: false,
                        filtering: false,
                        columnSetting: false,
                        selection: "multiple",
                        rowKey: "userId",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "추가", icon: "add" },
                                  })
                                : _vm._e(),
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "삭제", icon: "remove" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c(
                    "c-mobile-table",
                    {
                      ref: "grid2",
                      attrs: {
                        title: "작업자재",
                        topBorderClass: "topcolor-orange",
                        columns: _vm.grid2.columns,
                        data: _vm.grid2.data,
                        gridHeightAuto: true,
                        isTitle: true,
                        editable: _vm.editable && !_vm.disabled,
                        hideBottom: true,
                        isExcelDown: false,
                        filtering: false,
                        columnSetting: false,
                        selection: "multiple",
                        rowKey: "userId",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "추가", icon: "add" },
                                  })
                                : _vm._e(),
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "삭제", icon: "remove" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c(
                    "c-mobile-table",
                    {
                      ref: "grid3",
                      attrs: {
                        title: "작업용역",
                        topBorderClass: "topcolor-orange",
                        columns: _vm.grid3.columns,
                        data: _vm.grid3.data,
                        gridHeightAuto: true,
                        isTitle: true,
                        editable: _vm.editable && !_vm.disabled,
                        hideBottom: true,
                        isExcelDown: false,
                        filtering: false,
                        columnSetting: false,
                        selection: "multiple",
                        rowKey: "userId",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "추가", icon: "add" },
                                  })
                                : _vm._e(),
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "삭제", icon: "remove" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c(
                    "c-mobile-table",
                    {
                      ref: "grid4",
                      attrs: {
                        title: "작업공구",
                        topBorderClass: "topcolor-orange",
                        columns: _vm.grid4.columns,
                        data: _vm.grid4.data,
                        gridHeightAuto: true,
                        isTitle: true,
                        editable: _vm.editable && !_vm.disabled,
                        hideBottom: true,
                        isExcelDown: false,
                        filtering: false,
                        columnSetting: false,
                        selection: "multiple",
                        rowKey: "userId",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "추가", icon: "add" },
                                  })
                                : _vm._e(),
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: { label: "삭제", icon: "remove" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }